<script setup>
const config = useRuntimeConfig().public
const loading1 = ref(true)
const loading2 = ref(true)
</script>

<template>
  <div class="options">
    <div class="options_label">
      Stilles group
    </div>
    <div class="options_snap">
      <div class="options_wrapper">
        <vExternal where="https://masinezaobradudrveta.com/" class="option">
          <div class="placeholder">
            <vSpinner v-if="loading1" :size="38" />
            <!-- <img
              :src="config.CMS + '/wp-content/uploads/2024/09/sicar-yu.jpg'"
              @load="loading1 = false"
            > -->
            <NuxtImg fit="resize" :src="config.CMS + '/wp-content/uploads/2024/09/sicar-yu.jpg'" alt
              @load="loading1 = false" :modifiers="{ w: 708, h: 20, q: 90 }" />
            <!-- <div class="overlay" /> -->
          </div>
          <div class="option_content">
            <div>
              <h5>Sicar YU - Mašine za obradu drveta</h5>
              <div class="h6">
                Generalni zastupnici mašina za obradu drveta Cehisa, Felder, Nimac, Putsch Meniconi, Maggi i Gis.
                Višegodišnje iskustvo, kvalitet, konkurentna cena i profesionalna tehnička služba koja kupcima uvek
                obezbeđuje adekvatnu obuku, servis i rezervne delove.
              </div>
            </div>
            <vIcon id="open_in_new" class="open_in_new" />
          </div>
        </vExternal>
        <vExternal where="https://stilles-gradnja.rs/" class="option">
          <div class="placeholder">
            <vSpinner v-if="loading2" :size="38" />
            <!-- <img :src="config.CMS + '/wp-content/uploads/2024/09/pap-pavla-12-fasada-1200x675-1.png'" alt
              @load="loading2 = false"> -->
            <NuxtImg :src="config.CMS + '/wp-content/uploads/2024/09/pap-pavla-12-fasada-1200x675-1.png'" alt
              @load="loading1 = false" :modifiers="{ w: 708, h: 213, q: 90 }" />
          </div>
          <div class="option_content">
            <div>
              <h5>Stilles gradnja</h5>
              <div class="h6">
                U samom jezgru Novog Sada, u ulici Pap Pavla 12, Stilles gradnja nastavlja tradiciju gradnje modernog,
                savremenog stambeno-poslovnog objekta uz upotrebu vrhunskih materijala i po vrlo visokim standardima sa
                svih građevinsko-tehničkih aspekata.
              </div>
            </div>
            <vIcon id="open_in_new" class="open_in_new" />
          </div>
        </vExternal>
      </div>
    </div>
  </div>
</template>

<style scoped>
.options_label {
  max-width: var(--max-width);
  margin: 0 auto;
  color: rgb(var(--color-rgb) / .5);
  padding-top: 32px;
  padding-bottom: 24px;
  font-weight: 800;
  line-height: 1;
}

.open_in_new {
  color: rgb(var(--color-rgb) / .5);
}

.options_wrapper {
  max-width: var(--max-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
}

.placeholder {
  aspect-ratio: 10/3;
  position: relative;
  border-radius: 4px;
  display: grid;
  place-items: center;
  color: rgb(var(--color-rgb) / .25);
  padding: 24px;
  background-color: rgb(var(--color-rgb) / .03);

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

}

.option_content {
  padding-top: 16px;
  display: grid;
  grid-template-columns: auto 24px;
  column-gap: 24px;

  & h5 {
    color: var(--color);
    line-height: 1.3;
    font-size: 24px;
    font-weight: 800;
    padding-bottom: 8px;
  }

  & .h6 {
    font-size: 14px;
    color: var(--color);
    line-height: 1.5;
    color: rgb(var(--color-rgb) / .5)
  }

  &:deep(svg) {
    margin-top: 4px;
  }
}

@media (max-width: 512px) {
  .options_label {
    font-size: 24px;
  }

  .options_snap {
    scroll-snap-type: x mandatory;
    box-sizing: content-box;
    margin-left: -24px;
    margin-right: -24px;
    display: flex;
    overflow: scroll hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    & h5 {
      line-height: 1;
    }

    & .placeholder {
      aspect-ratio: 1;
    }

    & .options_wrapper {
      padding: 0 24px 0;
      gap: 24px;
      width: auto;
      margin-left: 0;
      margin-right: 0;
      display: flex;
    }

    & .option_content {
      gap: 8px;
    }

    & .option {
      scroll-snap-align: center;
      flex-direction: column;
      width: 75vw;
      display: flex;
    }
  }
}
</style>
