<script setup>
// const ct = useCookie('ct')
const cart = useCartStore()
const auth = useAuthStore()
const router = useRouter()
const close = () => cart.useMiniCart(false)
const config = useRuntimeConfig()
const mobile = ref(false)

function isMobile() {
  const { matches } = window.matchMedia('(max-width: 480px)')
  mobile.value = matches
}

onMounted(() => {
  isMobile()
  window.addEventListener('resize', isMobile)
})
onBeforeUnmount(() => window.removeEventListener('resize', isMobile))

watch(() => cart.miniCart, (x) => {
  const elem = document.body
  elem.style.overflowY = x ? 'hidden' : 'auto'
  // if (!mobile.value) {
  //   document.getElementById('__nuxt').style.transform = x ? 'translate3d(-400px, 0, 0)' : 'translate3d(0, 0, 0)'
  // }
})

watch(() => cart.count, (x) => {
  if (x === 0) close()
})
const formatter = new Intl.NumberFormat('sr-RS', {
  style: 'currency',
  currency: 'RSD',
  minimumFractionDigits: 2
})
const updateItem = async (key, quantity) => {
  try {
    const ct = useCookie('ct')
    const headers = {
      'cart-token': ct.value,
      'content-type': 'application/json'
    }
    // if (auth.me) headers['user-id'] = auth.me?.user_id
    cart.useLoading(true)
    const req = await fetch(config.public.CMS + '/wp-json/wc/store/cart/update-item', {
      method: 'post',
      headers,
      body: JSON.stringify({ key, quantity })
    })
    const res = await req.json()
    cart.updateCart(res)
  }
  catch (error) {
    console.error(error)
  }
  finally {
    cart.useLoading(false)
  }
}
const remove = async (key) => {
  const ct = useCookie('ct')
  const headers = {
    'cart-token': ct.value,
    'content-type': 'application/json'
  }
  if (auth.me) headers['user-id'] = auth.me?.user_id

  const x = await (
    await fetch(config.public.CMS + '/wp-json/wc/store/cart/remove-item', {
      method: 'POST',
      headers,
      body: JSON.stringify({ key })
    })
  ).json()
  cart.updateCart(x)
}

const viewCart = () => {
  close()
  router.push({ name: 'korpa' })
}
const viewNext = () => {
  close()
  router.push({ name: 'dostava' })
}
</script>

<template>
  <Teleport to="body">
    <div
      v-show="cart.miniCart"
      class="cart"
      @click="close"
    >
      <Transition :name="mobile ? 'crtmobile' : 'crt'">
        <div
          v-show="cart.miniCart"
          class="cart_aside"
        >
          <div class="scroll">
            <div class="header">
              <!-- {{ mobile }} -->
              <span>Tvoja korpa</span>

              <svg
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                @click="close"
              >
                <path
                  d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
                />
              </svg>
            </div>
            <div class="h2">
              <span>Pregled porudžbine ({{ cart.quantitySum }})</span>
            </div>
            <vAsideCart />
            <div class="ctas">
              <div class="ctas_container">
                <button
                  class="outline"
                  @click="viewNext"
                >
                  PLAĆANJE
                </button>
                <button @click="viewCart">
                  KORPA
                </button>
              </div>
            </div>
            <div class="items">
              <div
                v-for="item, key in cart.cart?.items"
                :key="key"
                class="item"
              >
                <div class="placeholder">
                  <img
                    v-if="item.images.length"
                    :src="item.images[0]?.src"
                    alt
                  >
                </div>
                <div>
                  <NuxtLink
                    :to="item?.permalink?.replace(/^.*\/\/[^\/]+/, '')"
                    class="name"
                    @click.close="close"
                  >
                    <span v-sanitize="item.name" />
                  </NuxtLink>

                  <!-- <DevOnly>
                    <pre>
          remainied {{ item.quantity }}
          minimum {{ item.quantity_limits.minimum }}
          maximum {{ item.quantity_limits.maximum }}
          item {{ item }}
        </pre>
                  </DevOnly> -->

                  <strong v-text="formatter.format(item.prices.price / 100)" />
                  <template v-if="item.prices.regular_price !== item.prices.sale_price">
                    <span
                      class="cart_price"
                      v-text="formatter.format(item.prices.regular_price / 100)"
                    />
                  </template>
                  <div class="cnt">
                    <vQuantity
                      :value="item.quantity"
                      :max="item.quantity_limits.maximum"
                      @onchange="$event => updateItem(item.key, $event)"
                    />
                    <span
                      class="remove"
                      @click.stop="remove(item.key)"
                    >Ukloni</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </Teleport>
</template>

<style scoped>
:deep(.quantity) {
  border: 1px solid rgb(0 0 0  / .25);
}
.crtmobile-enter-active,
.crtmobile-leave-active,
.crt-enter-active,
.crt-leave-active {
  transition: transform 256ms ease;
}

.crt-enter-from,
.crt-leave-to {
  transform: translate3d(400px, 0, 0);
}

.crtmobile-enter-from,
.crtmobile-leave-to {
  transform: translate3d(0, 100%, 0);
}

:deep(.quantity) {
  width: auto;
}

.scroll {
  overflow-y: auto;
  height: 100%;
}
.ctas {
  display: flex;
  justify-content: flex-end;

}
.ctas_container {
  display: flex;

  gap: 16px;
}

.cnt {
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
}

.cart_price {
  text-decoration: line-through;
  color: tomato;
  line-height: 1;
  padding-left: 8px;
  font-size: 13px;
}

.items {
  padding-top: 32px;
}

.name {
  display: block;
  font-size: 13px;
}

strong {
  font-weight: 800;
  font-size: 13px;
}

.item {
  display: grid;
  grid-template-columns: 48px auto;
  gap: 16px;
  padding-bottom: 8px;
  margin-bottom: 8px;

  & .placeholder {
    aspect-ratio: 1;
    background-color: rgb(0 0 0 / .06);
    position: relative;
    display: grid;
    place-items: center;

    & img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      /* mix-blend-mode: multiply; */
    }
  }
}

.cart {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgb(0 0 0 / .5);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  /* backdrop-filter: blur(4px); */
}

.cart_aside {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  bottom: 0;
  background-color: white;
  color: black;
  padding: 24px 24px 0;
  z-index: 21;
}

button {
  --size: 36px;
  --half: calc(var(--size) / 2);
  display: grid;
  place-items: center;
  height: var(--size);
  background-color: var(--brand-color);
  color: white;
  width: 100%;
  font-size: 14px;
  line-height: 1;
  padding: 0 var(--half);
  text-align: center;
  border-radius: var(--size);
  font-weight: 500;
  box-shadow: var(--box-shadow);

  &:active {
    transform: scale(.95);
    box-shadow: none;
  }

  &.outline {
    background-color: white;
    color: rgb(0 0 0 / .5);
    box-shadow: none;
    border: 1px solid rgb(0 0 0  / .25);
  }

  &:disabled {
    box-shadow: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  font-weight: 800;
  font-size: 21px;
  position: sticky;
  top: 0;
  padding: 8px 0;
  background-color: white;

  & svg {
    cursor: pointer;
  }
}

.h2 {
  padding-bottom: 16px;
  font-weight: 800;
}

.remove {
  font-weight: 600;
  font-size: 14px;
}

@media (max-width: 512px) {
  .cart_aside {
    top: 85px;
    width: 100%;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
